<template>
  <div class="about">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 mb-5">
          <about-directory />
        </div>
        <team-item
          v-for="(item, index) in employees"
          :key="index"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AboutDirectory from "./AboutDirectory.vue";
import TeamItem from "./TeamItem.vue";

export default {
  name: "about-content",
  computed: {
    ...mapGetters({
      employees: "about/getEmployees",
    }),
  },
  components: {
    AboutDirectory,
    TeamItem,
  },
};
</script>