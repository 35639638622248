<template>
  <div class="about-page">
    <page-cover :cover="cover"  v-if="cover" />
    <About />
  </div>
</template>
<script>
import PageCover from "@/components/shared/PageCover.vue";
import About from "@/components/about/About.vue";
import { mapGetters } from "vuex";
export default {
  name: "about",
  computed: {
    ...mapGetters({
      cover: "pageCover/getAbout",
    }),
  },
  created() {
    this.$store.dispatch("about/getEmployees");
  },
  components: {
    PageCover,
    About,
  },
};
</script>