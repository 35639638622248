<template>
  <div class="col-lg-4">
    <div class="team-item">
      <div class="team-item__inner">
        <div class="team-item__image">
          <img :src="item.image" />
        </div>
        <div class="team-item__name">
          {{ item.fullname }}
        </div>
        <div class="team-item__position">
          {{ item.position }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>