<template>
  <div class="about-directory team-item" v-if="director">
    <div class="team-item__inner">
      <div class="row">
        <div class="col-md-6">
          <div class="about-directory__main-photo">
            <img :src="director.image" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="about-directory__content">
            <div class="about-directory__icon">
              <img src="@/assets/images/icons/quotation.svg" />
            </div>
            <div class="about-directory__director">
              <h4 class="about-directory__name">
                {{ director.fullname }}
              </h4>
              <p class="about-directory__position">
                {{ director.position }}
              </p>
              <p
                class="about-directory__desc editor"
                v-html="director.description"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      director: "about/getChief",
    }),
  },
};
</script>